import React, { useState, useReducer } from "react";
import { Button } from '@mui/material';
import { NavLink } from "react-router-dom";
import SideNav from "../../../components/SideNav";
import ArrowUp from "../../../assets/fonts/ArrowRight.svg";
import EmployeesList from "./Employees"
import Groups from "./Groups"
import Forms from "./Forms";
import Training from "./Trainings";
import ViewPopup from "../Popups/View";
import CompletedFormList from "./CompletedForms";
import Docs from "./Docs";

const initialTabStatus = {
    employees: true,
    groups: false,
    forms: false,
    trainings: false,
    completedForms: false,
    docs: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE": 
            state[action.tab] = true; 
            return { ...state};
        default:
            return state;
    }
};

const index = (params) => {
    let userDetails = localStorage.getItem("role");
    userDetails = JSON.parse(userDetails);
    const id = params.match.params.id;
    const [qrModel, setQrModel] = useState(false);
    const [tabs, dispatch] = useReducer(reducer, initialTabStatus);

    const close = () => {
        setQrModel(false)
    }

    const handleUpdateTab = (tab) => {
        dispatch({ type: "UPDATE", tab: tab });
    };

    return (
        <React.Fragment>
            {/* <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav /> */}
                    <div className="content-wrap asset-tracking-wrap risk-assessment Internal-sub-employee">
                        <div className="title-tab-wrap" style={{ marginTop: "50px" }}>
                            <div className="title-card">
                                <div className="title-content">
                                    <NavLink
                                        to={'/subcontractor/managesubs'}
                                        id=""
                                        role="button"
                                        className="rat-links"
                                    >
                                        <div>
                                            <img src={ArrowUp} alt="back-arrow" className="back-arrow" />
                                        </div>
                                    </NavLink>
                                    <h4>
                                        <img src="/Company.svg" alt="Company" className="company-logo" />
                                        <b>{params.match.params.name}</b>
                                    </h4>
                                </div>
                                <div>
                                    <Button className="btn-cancel" onClick={(e) => {
                                        setQrModel(true)
                                    }}>View Company Info</Button>
                                </div>
                            </div>
                            <ul className="nav nav-tabs">
                                <li className="active">
                                    <a data-toggle="tab" href="#employees">
                                        Employees
                                    </a>
                                </li>
                                {(userDetails && userDetails.name == "Manager" || userDetails.name == "Admin" || userDetails && userDetails.name == "SuperAdmin") &&
                                    <li onClick={(e) => {
                                        handleUpdateTab('groups')
                                    }}>
                                        <a data-toggle="tab" href="#groups">
                                            Shared Groups
                                        </a>
                                    </li>
                                }
                                <li onClick={(e) => { handleUpdateTab('forms') }}>
                                    <a data-toggle="tab" href="#forms">
                                        Forms
                                    </a>
                                </li>
                                <li onClick={(e) => { handleUpdateTab('trainings') }}>
                                    <a data-toggle="tab" href="#trainings">
                                        Trainings
                                    </a>
                                </li>
                                <li onClick={(e) => { handleUpdateTab('completedForms') }}>
                                    <a data-toggle="tab" href="#completedForms">
                                        Completed Forms
                                    </a>
                                </li>
                                <li onClick={(e) => { handleUpdateTab('docs') }}>
                                    <a data-toggle="tab" href="#docs">
                                        Documents
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div id="employees" className="tab-pane fade in active">
                                {tabs.employees && <EmployeesList id={id} />}
                            </div>
                            <div id="groups" className="tab-pane fade in ">
                                {tabs.groups && <Groups id={id} />}
                            </div>
                            <div id="forms" className="tab-pane fade in ">
                                {tabs.forms && <Forms id={id} />}
                            </div>
                            <div id="trainings" className="tab-pane fade in ">
                                {tabs.trainings && <Training id={id} />}
                            </div>
                            <div id="completedForms" className="tab-pane fade in ">
                                {tabs.completedForms && <CompletedFormList id={id} />}
                            </div>
                            <div id="docs" className="tab-pane fade in ">
                                {tabs.docs && <Docs id={id} />}
                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
            {qrModel &&
                <ViewPopup show={qrModel} closepopup={close} details={id} />
            }
        </React.Fragment>
    );
};

export default index;