import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal'; // Ensure this path is correct
import { User } from 'pages/HomePage/Login'; // Assuming User is imported correctly

const AccountStatus = (props) => {
    const [subscriptionStatus, setSubscriptionStatus] = useState(null); // To store subscription status
    const [currentUser, setCurrentUser] = useState(null); // To store subscription status
    const [loading, setLoading] = useState(true); // To manage loading state
    const [isModalVisible, setModalVisible] = useState(false); // Modal visibility state
    const [isModalInvoicesVisible, setModalInvoicesVisible] = useState(false); // Modal visibility state
    
    const [portalLink, setPortalLink] = useState('');
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        setCurrentUser(User); // ✅ This updates state asynchronously
    }, []); // Runs only on mount
    

    useEffect(() => {
        // Fetch the subscription ID from User object
        const subscriptionId = User.companyInfo.external_id;
        console.log('User object:', User);
        console.log('Current User:', currentUser);
        console.log('Subscription ID:', subscriptionId);
    
        
        if (subscriptionId) {
            axios.get(`/api/v2/subscription_invoices/${subscriptionId}`)
                .then(response => {
                    if(response.data.data.length > 0){
                    
                        try {
                            setInvoices(response.data.data);
                        } catch (error) {
                            console.error('Error while processing subscription data', error);
                        }
                        
                        const subscriptionState2 = response.data.subscription_data.state;
                        if (subscriptionState2 === 'unpaid' || subscriptionState2 === 'on_hold') {
                        
                        } else {
                            
                            if(currentUser && currentUser.role === 'Admin' && response.data.subscription_data.state === 'past_due'){
                                setModalInvoicesVisible(true);
                            } else if(currentUser && currentUser.role === 'Admin') {
                                // if the subscription is active but no official pastdue on the account - sometimes happens when we override on maxio.  Review the invoice due date and ensure it is in the past.  This will allow us to show the past due modal.
                                
                                const now = new Date();

                                const hasPastDue = response.data.data.some(invoice => {
                                    const dueDate = new Date(invoice.due_date);
                                    return dueDate < now;
                                });
                            
                                if (hasPastDue) {
                                    setModalInvoicesVisible(true);
                                }
                            }
                        }
                    }
                    
                })
                .finally(() => {

                });

            axios.get(`/api/v2/subscriptions/${subscriptionId}`)
                .then(response => {
                    try {
                        console.log('API Response:', response.data);
                        const subscriptionDataTemp = JSON.parse(response.data.data);
                        const subscriptionData = subscriptionDataTemp[0];
                        // Assuming subscriptionData has the correct structure
    
                        if (subscriptionData && subscriptionData.subscription && subscriptionData.subscription.state) {
                            const subscriptionState = subscriptionData.subscription.state;
                            setSubscriptionStatus(subscriptionState);
                            
                            console.log('Subscription State:', subscriptionState);
    
                            // Show modal if the status is unpaid

                        
                            if (subscriptionState === 'unpaid' || subscriptionState === 'on_hold') {
    
                                setPortalLink(response.data.portal_link.toString());
    
                                setModalVisible(true);
   
                                
                                // Ensure the CSS style change is applied
                               
                                const sidebar = document.querySelector('.wrapper .sidebar');
                                if (sidebar) {
                                    // Temporarily remove position style
                                    sidebar.style.cssText += `position: unset !important;`; // Ensure other styles are preserved
                                    console.log('Sidebar position removed');
                                } else {
                                    console.warn('.wrapper .sidebar not found');
                                }


                            }
                        } else {
                            console.error('Unexpected response structure:', response.data);
                        }
                    } catch (error) {
                        console.error('Error while processing subscription data', error);
                    }
                })
                .catch(error => console.error('Error fetching subscription status:', error))
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error('Invalid subscription ID');
            setLoading(false);
        }
    }, [currentUser]); // Empty dependency array means this effect runs once after the initial render

    if (loading) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <div>
            <div
            style={{
                color:
                subscriptionStatus === 'past_due'
                    ? 'rgb(255, 255, 255)'
                    : 'rgb(240, 173, 78)',
                backgroundColor:
                subscriptionStatus === 'past_due'
                    ? 'rgb(217, 83, 79)'
                    : 'rgba(240, 173, 78, 0.3)',
                display: 'flex',
                alignItems: 'center',
                padding: '11px 8px',
                float: 'left',
                marginRight: '16px',
                borderRadius: '4px',
            }}
            >
            {subscriptionStatus === 'trialing' ? (
                <span>
                <span>Welcome, you are currently in our trial </span>
                {/* <a style={{color: "#f0ad4e", fontWeight: "600", textDecoration: "underline", lineHeight: "41px !important"}} href="/company_profile/billing_page" className="waves-effect waves-light">Pay Now</a> */}
                </span>
            ) : subscriptionStatus === 'past_due' ? (
                <span>
                <span>Your subscription is more than <u>30</u> days past due. Your company will not be able to access SafetyLoop soon </span>
                <a
                    style={{
                    color: 'rgb(255, 255, 255)',
                    fontWeight: '600',
                    textDecoration: 'underline',
                    lineHeight: '41px !important',
                    }}
                    href='/company_profile/billing_page'
                    className='waves-effect waves-light'
                >
                    Pay Now
                </a>
                </span>
            ) : subscriptionStatus === 'active2' ? (
            
                <span>
                <span>Your subscription is more than <u>30</u> days past due. Your company will not be able to access SafetyLoop very soon. </span>
                <a
                    style={{
                    color: 'rgb(255, 255, 255)',
                    fontWeight: '600',
                    textDecoration: 'underline',
                    lineHeight: '41px !important',
                    }}
                    href='/company_profile/billing_page'
                    className='waves-effect waves-light'
                >
                    Pay Now
                </a>
                </span>
            ) : subscriptionStatus === 'unpaid' ? (
                <span>
                <span style={{ fontSize: '14px' }}>
                    Your subscription is more than 45 days past due. Your company will not be able to access SafetyLoop soon{' '}
                </span>
                
                </span>
            ) : (
                <span>
                <span style={{ marginRight: '0px' }}>Active</span>
                {/* {User.role === 'Admin' &&
                    (<a style={{ color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important' }} href='/company_profile/billing_page' className='waves-effect waves-light'>Manage Billing</a>)} */}
                </span>
            )}
            </div>
            <Modal isVisible={isModalVisible}>
                <img src="https://www.safetyloop.com/New-logo.svg" alt="SL Logo" className="modal-logo" />
                <div className="modal-message">
                    <h2>Subscription Overdue - Action required</h2>
                    <p>Your subscription is more than <span style={{ textDecoration: 'underline' }}>45 days</span> past due. Your company will not be able to access the admin side of SafetyLoop until payment is received.</p>
                    <p>We value safety and understand its importance. Therefore, the mobile entry side for forms and other functionalities will remain accessible for the next 15 days. After this period, access will be suspended unless payment in full is received. </p>
                    <a style={{color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important'}} href={portalLink} target="_blank" className='waves-effect waves-light'>Pay Now</a> <i className="fa fa-external-link"></i>
                    <p style={{fontSize:'12px'}}>Click on the pay now, and "Update Payment Method" to add a new card on file.  You can also go into invoices, and pay each outstanding invoice directly using a Credit Card or ACH.</p>

                    <div className="contact-info">
                        <p>If you have any questions, please contact SafetyLoop:</p>
                        <p>Phone: 833-444-9876</p>
                        <p>Email: support@safetyloop.com</p>
                        {/* <a style={{ color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important', display: 'inline-block', marginTop: '10px' }} href={portalLink} target="_blank" className='waves-effect waves-light'>Access Detailed Billing</a> <i className="fa fa-external-link"></i> */}
                    </div>
                </div>
            </Modal>

            <Modal isVisible={isModalInvoicesVisible}>
                <div style={{ position: 'relative' }}>
                    {/* Close button */}
                    <button
                        onClick={() => setModalInvoicesVisible(false)}
                        style={{
                            position: 'relative',
                            top: '10px',
                            right: '-1107px',
                            background: 'transparent',
                            border: 'none',
                            fontSize: '20px',
                            cursor: 'pointer',
                        }}
                    >
                        &times;
                    </button>

                    <img
                        src="https://www.safetyloop.com/New-logo.svg"
                        alt="SL Logo"
                        className="modal-logo"
                    />
                    <div className="modal-message">
                        <h2>Subscription Past Due</h2>
                        <p>
                            Your subscription is
                            past due.
                            This message will appear for a few more days and then your company
                            will not be able to access the admin side of SafetyLoop until payment is
                            received.  We really appreciate your business and want to help you
                            maintain your subscription.
                        </p>
                        <p>
                            We value safety and understand its importance. Therefore, the mobile entry
                            side for forms and other functionalities will remain accessible for the next
                            30 days. After this period, access will be suspended unless payment in full
                            is received.
                        </p>

                        <div className="invoice-list">
                            <h2>Outstanding Invoices</h2>
                            {invoices.length > 0 ? (
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                                Invoice Number
                                            </th>
                                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                                Issue Date
                                            </th>
                                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                                Due Date
                                            </th>
                                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                                Total Amount
                                            </th>
                                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                                Status
                                            </th>
                                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                                Payment Link
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices.map((invoice) => (
                                            <tr key={invoice.uid}>
                                                <td style={{ padding: '8px' }}>{invoice.number}</td>
                                                <td style={{ padding: '8px' }}>{invoice.issue_date}</td>
                                                <td style={{ padding: '8px' }}>{invoice.due_date}</td>
                                                <td style={{ padding: '8px' }}>{`${invoice.currency} $${invoice.total_amount}`}</td>
                                                <td style={{ padding: '8px' }}>{invoice.status}</td>
                                                <td style={{ padding: '8px' }}>
                                                    <a
                                                        href={invoice.public_url}
                                                        target="_blank"
                                                        style={{
                                                            color: '#f0ad4e',
                                                            fontWeight: '600',
                                                            textDecoration: 'underline',
                                                        }}
                                                        className="waves-effect waves-light"
                                                    >
                                                        See Invoice and Pay Now
                                                    </a>
                                                    <i className="fa fa-external-link"></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No outstanding invoices.</p>
                            )}
                        </div>
                        <br />
                        <div className="contact-info">
                            <p>If you have any questions, please contact SafetyLoop:</p>
                            <p>Phone: 833-444-9876</p>
                            <p>Email: support@safetyloop.com</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AccountStatus;
