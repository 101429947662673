import React, { useEffect, useState } from "react";
import { MenuItem, FormHelperText, Select } from "@mui/material";
import {
    Modal,
    FormControl as Input,
} from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import Application, { user } from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import DOCSIcon from "../../../../assets/fonts/docs.png";


export default function CreateTrainings(props) {
    const { show, closepopup, users2, details, editObj, parentId, topicsList } = props;
    const [onSubmit, setSubmit] = useState(false);
    const [checked, setChecked] = useState([]);
    const [checkedRight, setCheckedRight] = useState([]);
    const [right, setRight] = useState([]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState();
    const [disabled, setDisable] = useState(false);
    const [selectedSearch, setSelectedSearch] = useState();
    const [values, setValues] = useState({
        internal_training_topic_id: "",
        date_of_training: "",
    });
    const [docs, setDocs] = useState([]);
    const [expiryValue, setExpiryValue] = useState([]);
    const [imageFile, setImageFile] = useState([]);
    const [deleteFile, setDeleteFile] = useState([]);
    const fileTypes = ["JPG", "PNG", "PDF"];

    useEffect(() => {
        debugger;
        if (editObj) {
            let obj = {};
            obj.date_of_training = moment(editObj.completion_date).format('yyyy-MM-DD');
            setExpiryValue(moment(editObj.expiry_date).format('yyyy-MM-DD'))
            let internal_training_topic = _.find(topicsList, (val) => {
                if (val.title == editObj.title) {
                    return val.identifier
                }
            });
            obj.internal_training_topic_id = internal_training_topic && internal_training_topic.identifier;
            getPageDetails(editObj)
            setValues(obj)
        }
        let selectedUsers = [];
        selectedUsers = editObj && editObj.training_participants || [];
        selectedUsers = _.map(selectedUsers, "user")
        selectedUsers = _.compact(selectedUsers)
        setRight(selectedUsers);
        selectedUsers = _.map(selectedUsers, "identifier")
        let usersss = users2
        
        details && setData(not(details, selectedUsers));
    }, [show])

    async function getPageDetails(editObj) {
        
        let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${parentId}/internal_trainings/${editObj.identifier}`);
        if (resultObj.status == 200) {
            let details = resultObj.data;
            let images = [...details.data.photos, ...details.data.files]
            setImageFile(images)
        }

    }

    function not(a, b) {
        return a.filter((value) => b.indexOf(value.identifier) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value.identifier) !== -1);
    }

    const handleToggleAll = (value, isChecked) => {
        if (isChecked) {
            let newValues = _.map(value, "identifier");
            setChecked([...newValues]);
        } else {
            setChecked([]);
        }
    }

    const handleToggleAllRight = (value, isChecked) => {
        if (isChecked) {
            let newValues = _.map(value, "identifier");
            setCheckedRight([...newValues]);
        } else {
            setCheckedRight([]);
        }
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    }

    const handleToggleRight = (value) => () => {
        const currentIndex = checkedRight.indexOf(value);
        const newChecked = [...checkedRight];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedRight(newChecked);
    }

    const handleCheckedRight = () => {
        let newchecked = intersection(data, checked);
        setRight([...right, ...newchecked]);
        setData(not(data, checked));
        setChecked([]);
    }

    const handleCheckedLeft = () => {
        let newchecked = intersection(right, checkedRight);
        setData([...data, ...newchecked]);
        setRight(not(right, checkedRight));
        setCheckedRight([]);
    }

    const createOrUpdate = async () => {
        try {
            setSubmit(true);
            setDisable(true);
            if (!values.internal_training_topic_id || !values.date_of_training || !expiryValue) {
                return false
            } else if (right.length == 0) {
                toast.error('Please select the user')
                setDisable(false);
                return false
            }

            const formData = new FormData();
            formData.append("internal_training_topic_id", values.internal_training_topic_id);
            formData.append("date_of_training", values.date_of_training);
            formData.append("expiry_date", expiryValue);

            [...right].forEach(obj => {
                formData.append("participant_ids[]", obj.identifier);
            });

            [...deleteFile].forEach(obj => {
                formData.append("file_ids[]", obj);
            });

            if (docs && docs.length > 0) {
                [...docs].forEach(obj => {
                    formData.append("files[]", obj);
                });
            }
            
            if (editObj && editObj.identifier) {
                await axios.patch(`${Application.api_url}/api/v2/internal_subs/${parentId}/internal_trainings/${editObj.identifier}`, formData).then((result) => {
                    if (result.data && result.data.success === true) {
                        toast.success(`Update Successfully`)
                        closepopup(result.data)
                    }
                }).catch((error) => {
                    error.response.data.message.map((msg) => {
                        toast.error(msg)
                    });
                })
            } else {
                await axios.post(`${Application.api_url}/api/v2/internal_subs/${parentId}/internal_trainings`, formData).then((result) => {
                    if (result.data && result.data.success === true) {
                        toast.success(`Created Successfully`)
                        closepopup(result.data)
                    }
                }).catch((error) => {
                    error.response.data.message.map((msg) => {
                        toast.error(msg)
                    });
                })
            }

        } catch (err) {
            console.log("err===>", err);
        }
    };

    const handleChange = (name, event) => {
        setValues({ ...values, [name]: event.target.value });
        if ((name == "internal_training_topic_id" && values.date_of_training) || (name == "date_of_training" && values.internal_training_topic_id)) {
            let expirationLable = {
                "no_expiration": "No Expiration",
                "five_year": "5",
                "four_year": "4",
                "three_year": "3",
                "two_year": "2",
                "one_year": "1"

            }
            let value = name == "internal_training_topic_id" ? event.target.value : values.internal_training_topic_id;
            let date_of_training_date = name == "date_of_training" ? event.target.value : values.date_of_training;
            let internal_training_topic = _.find(topicsList, (val) => {
                if (val.identifier == value) {
                    return val.identifier
                }
            });
            if (internal_training_topic.expiry_value != "no_expiration") {
                let date = moment(date_of_training_date).add(expirationLable[internal_training_topic.expiry_value], 'year').format('YYYY-MM-DD')
                setExpiryValue(date);
            } else {
                toast.info("The topic has no expiration")
            }
        }
        setSubmit(false);
        setDisable(false)
    };

    const handleFileChange = (file) => {

        // var allowedTypes = ['image/png', 'image/jpg', 'application/pdf']; 
        // console.log(file)
        // if (!allowedTypes.includes(file.type)) {
        //     toast.info('Only JPG, PNG, and PDF files are allowed.');
        //     return false;
        // }

        setDocs([...docs, ...file]);

        Promise.all(
            [...docs, ...file].map((files) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        resolve(reader.result);
                    };

                    reader.onerror = reject;

                    reader.readAsDataURL(files);
                });
            })
        ).then((imagesDataUrls) => {
            setImageFile(imagesDataUrls);
        });
    };

    const handleDelete = (deleteId) => {
        if (editObj && editObj.identifier) {
            let newFiles = imageFile.filter((val) => {
                if (val.id != deleteId) {
                    return val;
                }
            })
            setImageFile(newFiles)
            setDeleteFile([...deleteFile, ...[deleteId]])
        } else {
            let newImageFile = imageFile.filter(function (item, index) {
                return index !== deleteId
            });
            let newDocs = docs.filter(function (item, index) {
                return index !== deleteId
            });
            setDocs(newDocs);
            setImageFile(newImageFile)
        }
    };

    function isImageURLValid(url, callback) {
        var img = new Image();
        img.onload = function () {
            callback(true);
        };
        img.onerror = function () {
            callback(false);
        };
        img.src = url;
    }

    function Showimg({ doc }) {
        const [isValid, setIsValid] = useState(false);

        useEffect(() => {
            isImageURLValid(doc.url || doc, setIsValid);
        }, [doc]);

        return (
            <div>
                {isValid ? (
                    <img
                        src={doc}
                        className="file-type"
                        alt="file"
                        style={{ width: '80px', height: 'auto' }}
                    />
                ) : (
                    <img
                        src={DOCSIcon}
                        className="file-type"
                        alt="file"
                        style={{ width: '80px', height: 'auto' }}
                    />
                )}
            </div>
        );
    }

    return (
        <Modal
            bsSize="lg"
            show={show}
            onHide={(e) => {
                closepopup();
            }}
            className="create-maintanance-popup internal-sub-employee-popup pf-model multi-select add-training"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <b> {editObj && editObj.identifier ? "Update Training" : "Add Training"} </b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="create-form">
                    <div className="row">
                        <div className="col-md-12">
                            <label>
                                Topic Name <text style={{ color: "red" }}>*</text>
                            </label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.internal_training_topic_id}
                                fullWidth
                                onChange={(e) => {
                                    handleChange("internal_training_topic_id", e);
                                }}
                            >
                                {topicsList.map((obj, index) => (
                                    <MenuItem key={index} value={obj.identifier} name={obj.expiry_value}>
                                        {obj.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText
                                className="inputErrorMsg"
                                error={!values.internal_training_topic_id && onSubmit}
                            >
                                {!values.internal_training_topic_id && onSubmit
                                    ? "Topic Name is required"
                                    : ""}
                            </FormHelperText>
                        </div>
                        <div className="col-md-4">
                            <label>
                                Completion of Training <text style={{ color: "red" }}>*</text>
                            </label>
                            <input
                                type="date" 
                                class="date-field"
                                id="date_of_training"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="date_of_training"
                                data-date="MM DD YYYY"
                                data-date-format="MM DD YYYY"
                                value={values.date_of_training}
                                onChange={(e) => {
                                    handleChange("date_of_training", e);
                                }}
                            />
                            <FormHelperText
                                className="inputErrorMsg"
                                error={!values.date_of_training && onSubmit}
                            >
                                {!values.date_of_training && onSubmit
                                    ? "Completion of Training is required"
                                    : ""}
                            </FormHelperText>
                        </div>
                        <div className="col-md-4">
                            <label>
                                Expiry of Training <text style={{ color: "red" }}>*</text>
                            </label>
                            <input
                                type="date"
                                min={new Date().toJSON().split("T")[0]}
                                class="date-field"
                                id="expiry_date"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="expiry_date"
                                data-date="MM DD YYYY"
                                data-date-format="MM DD YYYY"
                                value={expiryValue}
                                onChange={(e) => {
                                    setExpiryValue(e.target.value);
                                }}
                            />

                            <FormHelperText
                                className="inputErrorMsg"
                                error={!expiryValue && onSubmit}
                            >
                                {!expiryValue && onSubmit
                                    ? "Expiry of Training is required"
                                    : ""}
                            </FormHelperText>
                        </div>
                        <div className="col-md-4">
                            <label>
                                Attachment
                            </label>
                            <FileUploader handleChange={handleFileChange} name="file" label="Upload or drop files here" multiple={true} className="drop-box" types={fileTypes}/>
                            {imageFile &&
                                <div className="attachment-grid">
                                    {imageFile && imageFile.map((doc, index) => (
                                        <div className="file-item">
                                            <Showimg doc={doc.url || doc} />
                                            <button
                                                className="delete-button"
                                                onClick={() => handleDelete(doc.id || index)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    backgroundColor: 'transparent',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    padding: '0'
                                                }}
                                            >
                                                <img
                                                    src="/packs/media/assets/fonts/Delete-62b3f74c182772359479dc3681fbd490.svg"
                                                    alt="Delete"
                                                    style={{
                                                        width: '24px',
                                                        height: '24px'
                                                    }}
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className="col-md-12">
                            <div className="topic-users-list d-flex">
                                <div className="transfer-list">
                                    <div className="unselected-users">
                                        <h4>Unselected Users</h4>
                                        <span class="badge badge-light">{Number(data.length)}</span>
                                        <input
                                            type="text"
                                            class="form-control assessment-name"
                                            placeholder="Search"
                                            aria-label="Username"
                                            id="asset-name2"
                                            aria-describedby="basic-addon1"
                                            name="name"
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                            value={search}
                                        />
                                        <img className="search-icon" src={SearchIcon} alt="" />
                                    </div>
                                    <div className="users-list">
                                        <ul>
                                            {data.length != 0 &&
                                                <div className="select-all">
                                                    <input type="checkbox" id="" value="1"
                                                        checked={data.length === checked.length && checked.length !== 0}
                                                        onClick={(e) => { handleToggleAll(data, e.target.checked) }}
                                                    />
                                                    Select All
                                                </div>
                                            }
                                            <div className="users-data">
                                                {
                                                    data.filter((item) => new RegExp(search, 'i').test(item.name))
                                                        .map((val, i) => (
                                                            <div className="users">
                                                                <input type="checkbox" id="" value={val.identifier} onClick={handleToggle(val.identifier)} checked={checked.indexOf(val.identifier) !== -1} />
                                                                {val.last_name} {val.first_name} - <span className="multi-select-email">{val.email}</span>
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <div className="move-btn">
                                    <div className="selected-btns mb-2">
                                        <button type="button" onClick={(e) => { handleCheckedRight() }}>&gt;</button>
                                    </div>
                                    <div className="unselected-btn">
                                        <button type="button" onClick={(e) => { handleCheckedLeft() }}>&lt;</button>
                                    </div>
                                </div>
                                <div className="transfer-list">
                                    <div className="selected-users">
                                        <h4>Selected Users</h4>
                                        <span class="badge badge-light">{Number(right.length)}</span>
                                        <input
                                            type="text"
                                            class="form-control assessment-name"
                                            placeholder="Search"
                                            aria-label="Username"
                                            id="asset-name2"
                                            aria-describedby="basic-addon1"
                                            name="name"
                                            onChange={(e) => {
                                                setSelectedSearch(e.target.value);
                                            }}
                                            value={selectedSearch}
                                        />
                                        <img className="search-icon" src={SearchIcon} alt="" />
                                    </div>
                                    <div className="users-list">
                                        <ul>
                                            {right.length != 0 &&
                                                <div className="select-all">
                                                    <input type="checkbox" id="" value="1"
                                                        checked={right.length === checkedRight.length && checkedRight.length !== 0}
                                                        onClick={(e) => { handleToggleAllRight(right, e.target.checked) }}
                                                    />
                                                    Select All
                                                </div>
                                            }
                                            <div className="users-data">
                                                {
                                                    right.filter((item) => new RegExp(selectedSearch, 'i').test(item.name))
                                                        .map((val, i) => (
                                                            <div className="users">
                                                                <input type="checkbox" id="" value={val.identifier} onClick={handleToggleRight(val.identifier)} checked={checkedRight.indexOf(val.identifier) !== -1} />
                                                                {val.last_name} {val.first_name} - <span className="multi-select-email">{val.email}</span>
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn button cancel-btn"
                    onClick={() => {
                        closepopup()
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    disabled={disabled}
                    onClick={(event) => {
                        createOrUpdate();
                    }}
                >
                    {editObj && editObj.identifier ? "Update" : "Create"}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
